import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import consts from "../consts";
import LoginCard from "../components/LoginCard";
import { useTrail, useSpring, animated as a } from "react-spring";
import Fade from "react-reveal/Fade";
import { useMediaQueryContext } from "./MediaQueryProvider";

const Root = styled.div`
  min-height: 600px;
  background: ${consts.colors.secondary};
  `

function Login() {
  const viewport = useMediaQueryContext();
  return (
    <Root>

      <LoginCard />
      

    </Root>
  );
}

export default Login;
