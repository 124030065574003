import React from "react";
import { Router, Route, Switch } from "react-router";

import { createBrowserHistory } from "history";
import HomePage from "./pages/HomePage";

import MediaQueryProvider from "./components/MediaQueryProvider";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ClientLogin from "./pages/ClientLogin";
import LogRocket from "logrocket";
import Normalize from "react-normalize";
import setupLogRocketReact from "logrocket-react";

const history = createBrowserHistory();

LogRocket.init("pylqb6/benel-co-tsgrs");
// after calling LogRocket.init()
setupLogRocketReact(LogRocket);

function App() {
  return (
    <Router history={history}>
      <MediaQueryProvider>
        <Normalize />
        <Header />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/login">
            <ClientLogin />
          </Route>
        </Switch>

        <Footer />
      </MediaQueryProvider>
    </Router>
  );
}

export default App;
