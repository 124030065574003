import React from "react";
import styled from "styled-components";
import consts from "../../consts";
import { useMediaQueryContext } from "../MediaQueryProvider";
import Fade from "react-reveal/Fade";
import { BackButton, GridTitle, GridTitleContainer } from "./common";
const Form = styled.div`
  margin: 2rem;
  display: flex;
  flex-direction: column;
`;

const Block = styled.div`
  height: 300px;
`;

function NewProjectRequest({ setStep, step, ...props }) {
  const viewport = useMediaQueryContext();

  return (
    <>
      <Fade delay={200}>
        <BackButton onClick={() => setStep(1)} />
      </Fade>
      <GridTitleContainer>
        <Fade>
          <GridTitle>Tell us more</GridTitle>
        </Fade>
      </GridTitleContainer>
      <Form>
        <Fade>
          <Block>asdasdad</Block>
        </Fade>
      </Form>
    </>
  );
}

export default NewProjectRequest;
