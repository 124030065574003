import React from "react";
import styled, { keyframes } from "styled-components";
import consts from "../consts";
// import { animated as a } from "react-spring";
import { useMediaQueryContext } from "./MediaQueryProvider";
import Fade from "react-reveal/Fade";

// const config = { mass: 5, tension: 2000, friction: 200 };

// const Image = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   height: inherit;
// }`;

// const ContentContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: unset;
//   flex: 1;
// `;

function Projects() {
  const viewport = useMediaQueryContext();

  const Root = styled.div`
    background: rgb(255, 226, 180);
    padding-top: 120px;
    padding-bottom: 80px;
  `;

  const GridContainer = styled.div`
    background-color: #202020;
    margin: 0 auto;
    max-width: calc(1400px - (2 * 3rem));
    display: flex;
    padding: 3rem;
    flex-direction: column;
    align-items: center;
  `;
  const Grid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 2rem;
    justify-items: center;
    align-items: center;
    & :before {
      content: "";
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
    & :first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
  `;

  // const BasicContainer = styled.div`
  //   max-width: 1400px;
  //   width: 100%;
  //   margin: 0rem auto;
  //   background-color: black;
  //   z-index: 1;
  //   color: white;
  //   font-weight: 600;
  //   display: grid;
  //   justify-content: center;

  //   grid-template-rows: repeat(
  //     auto-fit,
  //     ${consts.projects.section.height[viewport]}vh
  //   );
  //   font-size: ${consts.projects.section.fontSize[viewport]}rem;
  // `;

  // const ProductContainer = styled(BasicContainer)`
  //   background-color: #000
  //   padding: 1rem;
  // `;

  const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }

`;

  const Project = styled.div`
    transition: transform 0.1s;
    &:hover {
      animation: ${bounce} 0.4s linear;
      animation-iteration-count: 1;

    }
    grid-template-rows: repeat(
      auto-fit,
      ${consts.projects.section.height[viewport]}vh
    );
    font-size: ${consts.projects.section.fontSize[viewport]}rem;
   `


  const GridTitle = styled.div`
    color: white;
    font-size: 3rem;
    font-weight: 900;
    align-self: center;
    justify-self: center;
    text-align: center;
    text-transform: uppercase;
    border: 4px solid white;
    margin-bottom: 32px;
    padding: 0 2rem;
  `;

  const items = [
    {
      name: "unbrew",
      address: "unbrew.co",
    },
    {
      name: "karenlynn",
      address: "karenlynn.art",
    },
  ];

  return (
    <Root>
      <GridContainer>
        <Fade>
          <GridTitle>Project Showcase</GridTitle>
        </Fade>
        <Grid>
          {items.map((site, i) => (
            <Fade delay={(i + 1) * 100}>
              <Project>
                <a href={`http://${site.address}?ref=benel.co`}>
                  <img
                    alt={site.name}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                    src={`images/${site.name}.webp`}
                  />
                </a>
              </Project>
            </Fade>
          ))}
        </Grid>
      </GridContainer>
    </Root>
  );
}

export default Projects;
