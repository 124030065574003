import React from "react";
import styled from "styled-components";
import consts from "../../consts";
import { useMediaQueryContext } from "../MediaQueryProvider";
import Fade from "react-reveal/Fade";
import { GridTitleContainer, GridTitle } from './common';

const Form = styled.div`
  margin: 2rem;
  display: flex;
  flex-direction: column;
`;


const OptionContainer = styled.div`
  color: white;
  font-size: 3rem;
  font-weight: 800;
  line-height: 4.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: white;
  
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`;

const Radio = styled.input`
  margin-right: 1rem;
  transition: all 0.3s;
  visibility: hidden;
`;

const Label = styled.label`
  transition: 0.3s all;
  box-shadow: inset 0px -3px 0px 0px #efefef;
  cursor: pointer;
  width: fill-available;
  &:hover {
    box-shadow: inset 0px -9px 0px 0px #efefef;
  }
`;

const Option = ({ children, ...props }) => {
  return (
    <OptionContainer {...props} role="button">
      <Radio type="radio" id={children} name="contact" value={children} />
      <Label for={children}>{children}</Label>
    </OptionContainer>
  );
};


function OptionSelector({ setStep, step }) {
  const viewport = useMediaQueryContext();

  const options = [
    {
      name: "New project request",
      step: 10,
    },
    {
      name: "Media inquiry",
      step: 20,
    },
    {
      name: "Something else",
      step: 30,
    },
  ];

  return (
    <>
    <div style={{ height: 24 }}>{'\u00A0'}</div>
      <GridTitleContainer>
        <Fade>
          <GridTitle>Get in touch</GridTitle>
        </Fade>
      </GridTitleContainer>
      <Form>
        <Options>
          {options.map((option, i) => (
            <Fade delay={(i + 1) * 150} key={option.name}>
              <Option onClick={() => setStep(option.step)}>{option.name}</Option>
            </Fade>
          ))}
        </Options>
      </Form>
    </>
  );
}

export default OptionSelector;
