import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

const Stepper = forwardRef(({ steps = [], initialStep = 1, ...props }, ref) => {
  const [step, setStep] = useState(initialStep);
  const [additionalProps, setAdditionalProps] = useState(initialStep);
  
  const stepProps = {
    ...props,
    ...additionalProps,
    step,
    setStep,
    steps,
  };

  const [StepComponent, setStepComponent] = useState(<div />);

  useImperativeHandle(ref, () => ({
    step,
    setStep,
  }));

  useEffect(() => {
    const currentStep = steps.find(_step => _step.position === step);
    if (currentStep) {
      const Component = currentStep.component;
      const nestedSteps = currentStep.steps;
      if (nestedSteps) {
        setStepComponent(
          <Stepper {...stepProps} setAdditionalProps={setAdditionalProps} {...currentStep?.data} steps={nestedSteps} />,
        );
      } else {
        setStepComponent(<Component setAdditionalProps={setAdditionalProps} {...currentStep?.data} {...stepProps} />);
      }
    } else {
      // todo maybe delete this
      alert('ERR IN STEPPER, check `position` in steps');
    }
  }, [step]);

  return StepComponent;
});

export default Stepper;
