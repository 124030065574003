import React from "react";
import styled from "styled-components";
import backIcon from "../../assets/back.svg";

export const GridTitle = styled.div`
  color: white;
  font-size: 3rem;
  font-weight: 900;
  align-self: center;
  justify-self: center;
  text-align: center;
  text-transform: uppercase;
  border: 4px solid white;
  margin-bottom: 32px;
  padding: 0 2rem;
`;

export const GridTitleContainer = styled.div`
  display: flex;
  align-self: center;
`;

export const BackButtonBase = styled.div`
  color: white;
  position: relative;
  top: 0;
  right: 0;
  text-transform: uppercase;
  cursor: pointer;
`;


const ButtonLabel = styled.span`
`;

export const BackButton = (props) => (
  <BackButtonBase role="button" {...props}>
    <ButtonLabel role="button">Back</ButtonLabel>
    <img style={{ width: 18, height: 18, margin: "0 9px" }} src={backIcon} />
  </BackButtonBase>
);
