import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import consts from "../consts";
import { useTrail, useSpring, animated as a } from "react-spring";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import loginIcon from "../assets/login.svg";
import backIcon from "../assets/back.svg";
import { withRouter } from "react-router";

import { useMediaQueryContext } from "./MediaQueryProvider";
const config = { mass: 5, tension: 2000, friction: 200 };

const LogoText = styled.div`
  display: flex;
  align-items: flex-end;
  jusify-content: flex-end;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 900;
  transform: translateY(-8px);
  overflow-y: hidden;
`;

const AnimatedText = styled(a.div)`
  height: 30px;
  overflow-y: hidden;
`;


const Benel = styled.span``;

const Co = styled.span`
  text-transform: lowercase;
`;

function Header({ location }) {
  const viewport = useMediaQueryContext();
  const header = useRef();
  // `current` points to the mounted img element.
  const [inProp, setInProp] = useState(false);
  const LogoTextItems = [<Benel>BENEL</Benel>, <Co>.co</Co>];
  const trail = useTrail(LogoTextItems.length, {
    config,
    from: { opacity: 0, x: 20, height: 0 },
    to: async (next) => {
      await next({
        opacity: inProp ? 1 : 0,
        x: inProp ? 0 : 20,
        height: inProp ? 30 : 0,
      });
    },
    delay: 400,
  });

  // const bizCardTopStyles = useSpring({ height: consts.header.container.height[viewport] });
  const buttonTrail = useSpring({
    top: (inProp ? 1 : -1) * 40,
  });
  // const bizCardTopStyles = useSpring({ height: consts.header.container.height[viewport] });
  const bizCardTopStyles = useSpring({
    height: (inProp ? 1 : 2) * consts.header.container.height[viewport],
  });

  const BizCardTop = styled(a.div)`
    background-color: ${consts.colors.main};
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    height: ${consts.header.container.height[viewport] * 2}px;
  `;

  const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: ${consts.header.logo.height[viewport]}px;
    height: ${consts.header.logo.height[viewport]}px;
    padding: ${consts.header.logo.padding[viewport]}px;
    font-size: ${consts.header.logo.fontSize[viewport]}px;
  `;

  const LogoOuter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${consts.header.container.height[viewport]}px;
    width: ${consts.header.container.height[viewport]}px;
  `;

  const ButtonOuter = styled.div`
    display: flex;
    justify-self: flex-end;
    margin-right: 3rem;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      margin-right: 1rem;
    }

  `;

  const ClientSignInButton = styled(Link)`
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    font-weight: 100;
    letter-spacing: 1.5px;
    background-color: #ff9e4b;
    color: white;
    text-decoration: unset;
    text-transform: uppercase;
    border: 10px solid #ff9e4b;
    position: relative;
    transition: all 0.2s;
    text-align: center;
    margin: auto;
    display: block;

    &:hover,
    &:focus {
      background: #ff870f;
      border: 10px solid #ff870f;
    }

    &:active {
      background: orange;
      border: 10px solid orange;
    }
  `;

  useEffect(() => {
    setInProp(true);
  });

  return (
    <BizCardTop ref={header} style={bizCardTopStyles}>
      <LogoOuter>
        <Logo>
          <LogoText>
            {trail.map(({ height, x, ...rest }, i) => {
              return (
                <AnimatedText
                  key={`logo-text-item-${i}`}
                  style={{
                    ...rest,
                    transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <a.div style={{ height }}>{LogoTextItems[i]}</a.div>
                </AnimatedText>
              );
            })}
          </LogoText>
        </Logo>
      </LogoOuter>
      <ButtonOuter>
        <Slide down delay={0}>
          <Fade delay={400}>
            {location?.pathname === "/" ? (
              <ClientSignInButton>
                <img
                  style={{ width: 18, height: 18, stroke: "black", color: 'black', }}
                  src={loginIcon}
                />
              </ClientSignInButton>
            ) : (
              <ClientSignInButton to="/">
                <img
                  style={{ width: 18, height: 18, stroke: "black", color: 'black', }}
                  src={backIcon}
                />
              </ClientSignInButton>
            )}
          </Fade>
        </Slide>
      </ButtonOuter>
    </BizCardTop>
  );
}

export default withRouter(Header);
