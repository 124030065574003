import React from "react";
import Login from "../components/Login";

function HomePage({ ...props }) {
  return (
      <>
        <Login />
      </>
  );
}

export default HomePage;
