import React from "react";
import styled from "styled-components";
// import { useMediaQueryContext } from "./MediaQueryProvider";

function Footer() {
  // const viewport = useMediaQueryContext();
  const Root = styled.div`
    background-color: #202020;
    color: white;
    width: 100vw;
  `;
  const Email = styled.a`
    color: white;
  `;
  const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 20px;
    justify-items: start;
    align-items: center;
    max-width: 1400px;
    padding: 2rem;
    margin: 0 auto;
  `;

  const Item = styled.div`
    display: grid;
    grid-template-rows: 50px 50px;
  `;
  const Tag = styled.div`
    font-size: 1rem;
  `;
  const Action = styled.div`
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
  `;

  return (
    <Root>
      <Grid>
        <Item>
          <Tag>Want to chat?</Tag>
          <Action>
            Drop a memo
            <Email href='mailto:team@benel.co'>ari@benel.co</Email>
            <Email href='tel:9084563664'>‪(646) 481-7096‬</Email>
          </Action>
        </Item>
        <Item>
          <Tag>Join our network</Tag>
          <Action>
            Learn more about our work
            <Email href='mailto:talent@benel.co'>talent@benel.co</Email>

          </Action>
        </Item>
        <Item>
          <Tag>Check us out on social media</Tag>
          <Action>
            <Email href='http://facebook.com/benel-co'>facebook</Email>
            <Email href='http://instagram.com/benel-co'>instagram</Email>
          </Action>
        </Item>
      </Grid>
    </Root>
  );
}

export default Footer;
