const theme = {
    colors: {
        main: '#a5a58d',
        secondary: '#ffe2b4',
        secondary_light: '#feeaca',
        tertiary: '#E2CCA4',
    },
    header: {
        container: {
            height: {
                'desktop': 192,
                'tablet': 166,
                'mobile': 128,
            },
            paddingBottom: {
                'desktop': 180,
                'tablet': 60,
                'mobile': 20, 
            },
            paddingRight: {
                'desktop': 120,
                'tablet': 60,
                'mobile': 20,
            }
        },
        logo: {
            height: {
                'desktop': 100,
                'tablet': 90,
                'mobile': 80,
            },
            padding: {
                'desktop': 12,
                'tablet': 8,
                'mobile': 6,
            },
            fontSize: {
                'desktop': 24,
                'tablet': 22,
                'mobile': 20,
            }
        }
    },
    contact: {
        section: {
            fontSize: {
                'desktop': 2.5,
                'tablet': 3,
                'mobile': 2,
            },
            height: {
                'desktop': 30,
                'tablet': 30,
                'mobile': 20,
            }
        },
        tagLine: {
            lineHeight: {
                'desktop': 3,
                'tablet': 3,
                'mobile': 2,
            },
            paddingLeft: {
                'desktop': 3,
                'tablet': 2.5,
                'mobile': 2,
            }
        },
    },
    tagLine: {
        lineHeight: {
            'desktop': 3,
            'tablet': 3,
            'mobile': 2,
        },
        paddingLeft: {
            'desktop': 3,
            'tablet': 2.5,
            'mobile': 2,
        }
    },
    projects: {
        section: {
            fontSize: {
                'desktop': 2.5,
                'tablet': 3,
                'mobile': 2,
            },
            height: {
                'desktop': 30,
                'tablet': 30,
                'mobile': 20,
            }
        },
    },
    cardBody: {
        container: {
            paddingBottom: {
                'desktop': 120,
                'tablet': 60,
                'mobile': 100,
            },
            paddingRight: {
                'desktop': 120,
                'tablet': 60,
                'mobile': 20,
            }
        },
        tag: {
            fontSize: {
                'desktop': 3.5,
                'tablet': 2.75,
                'mobile': 2.25,
            }
        }
    }
};

export default theme;
