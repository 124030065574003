import React from "react";
import BizCardBottom from "../components/BizCardBottom";
import Taglines from "../components/Taglines";
import Projects from "../components/Projects";
import GetInTouch from "../components/GetInTouch";

function HomePage({ ...props }) {
  return (
      <>
        <BizCardBottom />
        <Taglines />
        <Projects />
        {/* <GetInTouch /> */}
      </>
  );
}

export default HomePage;
