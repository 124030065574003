import React from "react";
import styled from "styled-components";
import consts from "../consts";
import { useTrail, useSpring, animated as a } from "react-spring";
import { useMediaQueryContext } from "./MediaQueryProvider";
const config = { mass: 5, tension: 2000, friction: 200 };

const items = ["CLEVER", "SIMPLE", "YOURS"];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-self: flex-end;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: unset;
  flex: 1;
`;

function BizCardBottom() {
  const viewport = useMediaQueryContext();

  const Root = styled.div`
    background: ${consts.colors.secondary};
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: calc(
      90vh - ${consts.header.container.height[viewport]}px -
        ${consts.header.container.paddingBottom[viewport]}px
    );
    padding-bottom: ${consts.cardBody.container.paddingBottom[viewport]}px;
    padding-right: ${consts.cardBody.container.paddingRight[viewport]}px;
  `;

  const Tag = styled(a.span)`
    font-size: ${consts.cardBody.tag.fontSize[viewport]}rem;
    font-weight: 900;
     text-transform: uppercase;
    overflow: hidden;
    text-shadow: 0px 0px, 0px 0px, -0.5px 0px;
    &:before {
      display: inline;
      content: "✔️";
      font-size: 24px;
      margin: 0 4px;
    }
  `;

  const trail = useTrail(items.length, {
    config,
    from: { y: 130, opacity: 0 },
    to: {
      opacity: 1,
      y: 0,
    },
    delay: 300,
  });

  const Bar = styled(a.div)`
    opacity: 1;
    border-top: 16px solid black;
    margin-top: 12px;
    margin-left: calc(
      (
          ${consts.header.container.height[viewport]}px -
            (${consts.header.logo.padding[viewport]}px*2) -
            ${consts.header.logo.height[viewport]}px
        ) / 2
    );
    width: calc(
      ${consts.header.logo.padding[viewport]}px + 2 *
        ${consts.header.logo.height[viewport]}px
    );
  `;

  const barStyles = useSpring({
    width:
      consts.header.logo.padding[viewport] * 2 +
      consts.header.logo.height[viewport],
    opacity: 1,
    from: {
      width: 0,
      opacity: 0.5,
    },
  });


  return (
    <Root>
      <ContentContainer>
        <Bar style={barStyles} />
        <Container>
          {trail.map(({ y, height, lineHeight, ...rest }, index) => (
            <Tag
              key={index}
              style={{
                ...rest,
                height: lineHeight,
                transform: y.interpolate((x) => `translate3d(${x}px, 0, 0)`),
              }}
            >
              {items[index]}
            </Tag>
          ))}
        </Container>
      </ContentContainer>
    </Root>
  );
}

export default BizCardBottom;
