import React, {createContext, useContext, useMemo} from 'react';
import useMedia from 'use-media';

export const MediaQueryContext = createContext(null);

const mediaQueries = {
  uhd: '(min-width: 1920px)',
  desktop: '(max-width: 1920px)',
  tablet: '(max-width: 960px)',
  mobile: '(max-width: 600px)',
};

export default function MediaQueryProvider({children}) {
  const mobile = useMedia(mediaQueries.mobile);
  const tablet = useMedia(mediaQueries.tablet);
  // const desktop = useMedia(mediaQueries.desktop);
  // const uhd = useMedia(mediaQueries.uhd);

  const viewport = useMemo(() => {
    if(mobile) {
      return 'mobile'
    } else if(tablet) {
      return 'tablet'
    } else {
      return 'desktop'
    } 
    /*else if(uhd) {
      return 'desktop' // todo
    } else {
      return 'desktop'
    }*/
  }, [
    mobile,
    tablet,
    // desktop,
    // uhd
  ]);

  return (
    <MediaQueryContext.Provider value={viewport}>
      {children}
    </MediaQueryContext.Provider>
  );
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext);
}