import React from "react";
import styled from "styled-components";
import consts from "../consts";
// import { animated as a } from "react-spring";
import { useMediaQueryContext } from "./MediaQueryProvider";
import Fade from "react-reveal/Fade";

// const config = { mass: 5, tension: 2000, friction: 200 };

const Image = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: inherit;
}`;

// const ContentContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: unset;
//   flex: 1;
// `;

function Projects() {
  const viewport = useMediaQueryContext();

  const Root = styled.div`
    background: rgb(255, 226, 180);
    padding-top: 120px;

  `;

  const Grid = styled.div`
    margin: 0 auto;
    max-width: 1400px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, auto));
    grid-template-rows: repeat(
      2,
      ${consts.projects.section.height[viewport]}vh
    );
    grid-gap: 1rem;
    @media only screen and (max-width: 960px) {
      grid-gap: unset;
    }
`;

  // const Bar = styled(a.div)`
  //   opacity: 1;
  //   border-top: 16px solid black;
  //   margin-top: 12px;
  //   margin-left: calc(
  //     (
  //         ${consts.header.container.height[viewport]}px -
  //           (${consts.header.logo.padding[viewport]}px*2) -
  //           ${consts.header.logo.height[viewport]}px
  //       ) / 2
  //   );
  //   width: calc(
  //     ${consts.header.logo.padding[viewport]}px + 2 *
  //       ${consts.header.logo.height[viewport]}px
  //   );
  // `;

  const TagLine = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    z-index: 1;
    & > div {
      padding-bottom: 18px;
    }
    line-height: ${consts.tagLine.lineHeight[viewport]}rem;
    padding-left: ${consts.tagLine.paddingLeft[viewport]}rem;
  `;

  const BasicContainer = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0rem auto;
    background-color: black;
    z-index: 1;
    color: white;
    font-weight: 800;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: repeat(
      auto-fit,
      ${consts.projects.section.height[viewport]}vh
      );
    grid-gap: 1rem;
    font-size: ${consts.projects.section.fontSize[viewport]}rem;
    @media only screen and (max-width: 600px) {
      grid-template-columns: 3fr 2fr;
      max-width: 100vw;
      margin: 0;
    }
  `;

  const ProductContainer = styled(BasicContainer)`
    background-color: #b7b7a4;
  `;

  const SkillContainer = styled(BasicContainer)`
    background-color: #c9ada7;
  `;

  const AnotherContainer = styled(BasicContainer)`
    background-color: #505050;
  `;

  const YetAnotherContainer = styled(BasicContainer)`
    background-color: #a26769;
  `;

  return (
    <Root>
      <Grid>

      <ProductContainer>
        <TagLine>
          Thoughtful
          <br />
          Design
        </TagLine>
        <Image>
          <Fade>
            <img
              alt='creative'
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              srcSet="
              images/creative-500w.webp 500w,
              images/creative-1000w.webp 1000w,
              images/creative-1500w.webp 1500w,
            "
              // sizes="(max-width: 600px) 480px,
              //       800px"
              // src="/products-800w.webp"
            />
          </Fade>
        </Image>
      </ProductContainer>
      <SkillContainer>
      <TagLine>
          Executed <br /> beatifully{" "}
        </TagLine>

        <Image>
          <Fade>
            <img
              alt='design'
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              srcSet="
              images/design-500w.webp 500w,
              images/design-1000w.webp 1000w,
              images/design-1500w.webp 1500w,
            "
          />
          </Fade>
        </Image>
      </SkillContainer>
      <AnotherContainer>
        <TagLine>
          Made to 
          <br/>
          fit you
        </TagLine>
        <Image>
          <Fade>
            <img
              alt='products'
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              srcSet="
              images/products-500w.webp 500w,
              images/products-1000w.webp 1000w,
              images/products-1500w.webp 1500w,
            "
            />
          </Fade>
        </Image>
      </AnotherContainer>
      <YetAnotherContainer>
        <TagLine>
          With growth 
          <br/>
          in mind
        </TagLine>
        <Image>
          <Fade>
            <img
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              alt='products'
              srcSet="
              images/products-500w.webp 500w,
              images/products-1000w.webp 1000w,
              images/products-1500w.webp 1500w,
            "
              // sizes="(max-width: 600px) 480px,
              //       800px"
              // src="/products-800w.webp"
            />
          </Fade>
        </Image>
      </YetAnotherContainer>
      </Grid>
    </Root>
  );
}

export default Projects;
